<template>
  <h2>ABOUT</h2>
  <div class="content" style="height:80vh">
    <div class="imagecontainer" id="about">
          <div><img src="../assets/me.jpg"></div>
          <div>
            <p style="margin:0;">Zachariah "Zach" Watson is a generative artist from Kansas City, Missouri. His work inserts the fluidity of human representation into the rigidity of computer algorithms.</p><br><br>
            <h2 style="margin:0;text-align:left;">CONTACT ME</h2><br>
            <p style="margin:0;">Email: watsonzachariah@gmail.com</p><br>
            <p style="margin:0;">Instagram: @zchwtsn</p><br>
            <p style="margin:0;">Twitter: @zchwtsn</p><br><br>
            </div>
      </div>
  </div>
</template>
